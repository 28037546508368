<template>
  <div class="p-4 p-md-5">
    <h3 class="text-center mb-2">NLU Web Portal</h3>
    <h6 class="text-center text-muted">Reset Password</h6>
    <div class="row justify-content-center py-md-2">
      <div class="col-lg-9">
        <b-form @submit.prevent="submit">
          <b-form-group>
            <b-form-input
              type="email"
              disabled
              v-model="form.email"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Password"
            invalid-feedback="Password must be atleast 8 characters"
          >
            <b-form-input
              type="password"
              v-model="$v.form.password.$model"
              :state="validateState($v.form.password)"
              class="i-ctrl"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Confirm Password"
            invalid-feedback="Password must match"
          >
            <b-form-input
              type="password"
              v-model="$v.form.password_confirmation.$model"
              :state="validateState($v.form.password_confirmation)"
              class="i-ctrl"
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" class="w-100 mt-2" variant="danger">
            <b-spinner small v-show="submitting"></b-spinner>
            Submit
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import * as api from "./api";
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        token: "",
      },
      submitting: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  mounted() {
    this.form.email = decodeURI(this.$route.query["email"]);
    this.form.token = this.$route.query["token"];
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.submitting = true;
        api.resetPassword(this.form).then(()=>{
            this.$router.push("/login");
        }).finally(()=>{
            this.submitting = false;
        })
    },
  },
};
</script>